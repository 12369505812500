.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#root{
  overflow: hidden;
  width: 100%;
  height: 100vh;
  display: table;
}

.App{
  position: relative;
  height: 100%;
}

/* nav css */
.iframeContainer{
  width: 100%;
  min-height: 250px;
  position: relative;
  height: calc(100vh - 90px);
  overflow-y: scroll;
  z-index: 9090;
}

.iframeContainer.disabled{
  pointer-events: none;
}

.iframeContainer iframe{
  width: 1px;
  min-width: 100%;
  border: 0;
  
  min-height: calc(100vh - 94px);
  z-index: 9090;
}

.navContainer{
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 9091;
  background-color: #ffffff;
}

.nav{
  width: auto;

}

div.vsm_icon1 { background-image: url('footer_frosch_startseite.svg'); }
div.vsm_icon2 { background-image: url('footer_megaphon_aktuelles.svg'); }
div.vsm_icon3 { background-image: url('footer_wegweiser_buergerservice.svg'); }
div.vsm_icon4 { background-image: url('footer_kalender_freizeit.svg'); }

div.vsm_first { border-right: 1px solid #c0c0c0!important; }
div.vsm_betwn { border-right: 1px solid #c0c0c0!important; }

div.viosubmenubutton{
  z-index: 9091;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center 10px;
  background-size: 35px;
  box-sizing: border-box;
  width: calc(25% - 2px);
  height: 85px;
  padding-top: 55px;
  text-align: center;
  position: relative;
  font-size: 13px;
  margin-bottom: 5px;
}

div.viosubmenusub{
	box-sizing: content-box;
	bottom: 120px;
	z-index: 9091;
	padding-bottom: 518;
	position: absolute;
	min-width: 249px;
	max-width: 249px;
	max-height: calc(100vh - 170px);
	overflow-y: auto;
	background-color: #3f3f3f;
	text-align: left;
	padding-left: 26px;
	padding-right: 26px;
	padding-top: 7px;
	padding-bottom: 7px;
	display: none;
}

span.viosubmenuitem{
	z-index: 9091;
	color: #ffffff;
	display: block;
	cursor: pointer;
	font-size: 15px;
	padding-top: 10px;
	padding-bottom: 10px;
}

div.viosubmenuarrow{
	z-index: 9091;
	position: absolute;
	bottom: 109px;
	left: calc(50% - 19px);
	background-image: url('menu_arr.svg');
	background-repeat: no-repeat;
	background-position: center bottom;
	background-size: 38px;
	width: 38px;
	height: 38px;
	display: none;
}

div.vsm_icon1 div.viosubmenusub{
  left: 0px;
}

div.vsm_icon4 div.viosubmenusub{
	right: 0px;
}

div.open .viosubmenusub, div.open .viosubmenuarrow{
  display: block;
}

div.permissionOverlay{
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: lightgrey;
  z-index: 9092;
}

div.permissionView{
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  padding: 10px;
  width: 250px;
  z-index: 9093;
}

div.permissionView span{
  display: table;
  margin-bottom: 15px;
}

div.permissionView .permissionButton{
  float: right;
  margin-right: 10px;
}

div.permissionView .permissionDeny{
  float: left;
  margin-left: 10px;
}

div.unsupported{
  display: none;
}